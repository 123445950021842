canvas {
    border: 1px solid black;
    /**/
    /*border-radius:2.5rem;
    margin-left:50px;*/
    background-color:white;
    border: 2px solid gray;
    position:relative;
    cursor:pointer;
    color:"#000000";
  }

  button{
    background-color: rgb(204, 166, 147);
    font-size: 1.2em;
  }

  button:hover{  
    background-color: rgb(66, 112, 237);
    color: whitesmoke;
    cursor:pointer;
}

  input
  {
    border:none;
    width:30px;
    height:30px;
    border-radius:5%;
    /*position:absolute;
    margin-left:10px;*/
    cursor:pointer;
    color:"#000000";
  }